<template>
  <b-row>

    <h2 class="pl-1">Локации</h2>

    <!--  BEFORE TABLE  -->
    <div class="d-flex justify-content-between col-12">
      <b-col
          md="4"
          class="my-1 px-0"
      >
        <b-form-group
            class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Искать"
            />
            <b-input-group-append>
              <b-button
                  :disabled="!filter"
                  @click="filter = ''"
              >
                Очистить
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!--   CREATE   -->
      <div
          class="my-1 float-right"
      >
        <modal-button
            ripple="rgba(113, 102, 240, 0.15)"
            size="xl"
            name="modal-main"
            modal-title="Создание"
            modalButton="Создать"
            @btn-emit="createLocation"
            @open-emit="clearInputs"
            variant="success"
            ref="create-modal-button"
        >
          <template v-slot:button @click="createLocation">Создать</template>

          <template v-slot:modal-body>
            <ValidationObserver ref="validation-observer">
              <div class="d-flex flex-wrap">

                <!--   ADDRESS [RU]   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Адрес [RU]`" rules="required" v-slot="{errors}">
                    <b-form-group
                        label="Адрес [RU]"
                        label-for="address_ru"
                    >
                      <b-form-input
                          v-model="description.address.ru"
                          id="address_ru"
                          size="lg"
                          placeholder="Адрес [RU]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   ADDRESS [UZ]   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Адрес [UZ]`" rules="required" v-slot="{errors}">
                    <b-form-group
                        label="Адрес [UZ]"
                        label-for="address_uz"
                    >
                      <b-form-input
                          v-model="description.address.uz"
                          id="address_uz"
                          size="lg"
                          placeholder="Адрес [UZ]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   CITY [RU]   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Город [RU]`" rules="required" v-slot="{errors}">
                    <b-form-group
                        label="Город [RU]"
                        label-for="city_ru"
                    >
                      <b-form-input
                          v-model="description.city.ru"
                          id="city_ru"
                          size="lg"
                          placeholder="Город [RU]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   CITY [UZ]   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Город [UZ]`" rules="required" v-slot="{errors}">
                    <b-form-group
                        label="Город [UZ]"
                        label-for="city_uz"
                    >
                      <b-form-input
                          v-model="description.city.uz"
                          id="city_uz"
                          size="lg"
                          placeholder="Город [UZ]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   TITLE [RU]   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Название [RU]`" rules="required" v-slot="{errors}">
                    <b-form-group
                        label="Название [RU]"
                        label-for="title_ru"
                    >
                      <b-form-input
                          v-model="title.ru"
                          id="title_ru"
                          size="lg"
                          placeholder="Название [RU]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   TITLE [UZ]   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Название [UZ]`" rules="required" v-slot="{errors}">
                    <b-form-group
                        label="Название [UZ]"
                        label-for="title_uz"
                    >
                      <b-form-input
                          v-model="title.uz"
                          id="title_uz"
                          size="lg"
                          placeholder="Название [UZ]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   WORKING TIME WEEKDAYS   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Время работы (Будни)`" rules="required" v-slot="{errors}">
                    <b-form-group
                        label="Время работы (Будни)"
                        label-for="weekdays"
                    >
                      <b-form-input
                          v-model="description.working_hours_weekdays"
                          id="weekdays"
                          size="lg"
                          placeholder="Время работы (Будни)"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   WORKING TIME WEEKENDS   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Время работы (Отдых)`" rules="required" v-slot="{errors}">
                    <b-form-group
                        label="Время работы (Отдых)"
                        label-for="weekends"
                    >
                      <b-form-input
                          v-model="description.working_hours_weekends"
                          id="weekends"
                          size="lg"
                          placeholder="Время работы (Отдых)"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   PHONE NUMBER - 1   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Телефон номера`" rules="required" v-slot="{errors}">
                    <label for="select">Номер телефона</label>
                    <v-select
                        id="select"
                        taggable
                        multiple
                        push-tags
                        label="title"
                        v-model="description.phoneNumbers"
                    >

                    </v-select>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   MAP LINK   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Ссылка карты`" rules="regex:https" v-slot="{errors}">
                    <b-form-group
                        label="Ссылка карты"
                        label-for="map_link"
                    >
                      <b-form-input
                          v-model="map_url"
                          id="map_link"
                          size="lg"
                          placeholder="Ссылка карты"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   LINK [RU]   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Ссылка [RU]`" rules="regex:https" v-slot="{errors}">
                    <b-form-group
                        label="Ссылка [RU]"
                        label-for="link[ru]"
                    >
                      <b-form-input
                          v-model="url.ru"
                          id="link[ru]"
                          size="lg"
                          placeholder="Ссылка [https]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   LINK [UZ]   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Ссылка [UZ]`" rules="regex:https" v-slot="{errors}">
                    <b-form-group
                        label="Ссылка [UZ]"
                        label-for="url_uz"
                    >
                      <b-form-input
                          v-model="url.uz"
                          id="url_ruz"
                          size="lg"
                          placeholder="Ссылка [https]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

              </div>
            </ValidationObserver>
          </template>
        </modal-button>

      </div>
    </div>

    <!--  TABLE  -->
    <b-col cols="12">
      <b-table
          striped
          hover
          responsive
          sort-icon-left
          :busy="isBusy"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
      >

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-1">Идет загрузка ...</strong>
          </div>
        </template>

        <template #cell(url)="data">
          <span v-if="data.item.url && data.item.url.ru !== 'null'">{{ data.item.url.ru }}</span>
        </template>

        <template #cell(map_url)="data">
          <span v-if="data.item.map_url && data.item.map_url !== 'null'">{{ data.item.map_url.substring(0, 15) }}</span>
        </template>

        <template #cell(description)="data">
          {{ data.item.description.ru }}
        </template>

        <template #cell(description.phone)="{item}">
          {{ splitPhoneNumbers(item) }}
        </template>

        <template #cell(region_id)="data">
          <span>
            {{ getSelectRegionName(regions, data.item) }}
<!--            {{ regions }}-->
<!--            <br><br><br>-->
<!--            {{ data.item }}-->
          </span>
        </template>

        <template #cell(crud_row)="data">
          <div class="d-flex float-right">

            <!--    EDIT    -->
            <modal-button
                class="crud__btn"
                ripple="rgba(113, 102, 240, 0.15)"
                size="xl"
                @btn-emit="updateLocation(data.item.id, data.item)"
                @open-emit="getOneLocation(data.item.id)"
                :name="`modal-edit-${data.item.id}`"
                modalButton="Сохранить"
                :id="data.item.id"
                modal-title="Редактировать"
                variant="flat-warning"
                ref="update-modal-button"
            >
              <template
                  v-slot:button
              >
                <feather-icon
                    icon="Edit2Icon"
                    size="21"
                />
              </template>

              <template v-slot:modal-body>
                <ValidationObserver ref="validation-observer">
                  <div class="d-flex flex-wrap">

                    <!--   ADDRESS [RU]   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Адрес [RU]`" rules="required" v-slot="{errors}">
                        <b-form-group
                            label="Адрес [RU]"
                            label-for="address_ru"
                        >
                          <b-form-input
                              v-model="description.address.ru"
                              id="address_ru"
                              size="lg"
                              placeholder="Адрес [RU]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   ADDRESS [UZ]   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Адрес [UZ]`" rules="required" v-slot="{errors}">
                        <b-form-group
                            label="Адрес [UZ]"
                            label-for="address_uz"
                        >
                          <b-form-input
                              v-model="description.address.uz"
                              id="address_uz"
                              size="lg"
                              placeholder="Адрес [UZ]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   CITY [RU]   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Город [RU]`" rules="required" v-slot="{errors}">
                        <b-form-group
                            label="Город [RU]"
                            label-for="city_ru"
                        >
                          <b-form-input
                              v-model="description.city.ru"
                              id="city_ru"
                              size="lg"
                              placeholder="Город [RU]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   CITY [UZ]   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Город [UZ]`" rules="required" v-slot="{errors}">
                        <b-form-group
                            label="Город [UZ]"
                            label-for="city_uz"
                        >
                          <b-form-input
                              v-model="description.city.uz"
                              id="city_uz"
                              size="lg"
                              placeholder="Город [UZ]"
                              :state="errors.length > 0 ? false : null"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   TITLE [RU]   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Название [RU]`" rules="required" v-slot="{errors}">
                        <b-form-group
                            label="Название [RU]"
                            label-for="title_ru"
                        >
                          <b-form-input
                              v-model="title.ru"
                              id="title_ru"
                              size="lg"
                              placeholder="Название [RU]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   TITLE [UZ]   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Название [UZ]`" rules="required" v-slot="{errors}">
                        <b-form-group
                            label="Название [UZ]"
                            label-for="title_uz"
                        >
                          <b-form-input
                              v-model="title.uz"
                              id="title_uz"
                              size="lg"
                              placeholder="Название [UZ]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   WORKING TIME WEEKDAYS   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Время работы (Будни)`" rules="required" v-slot="{errors}">
                        <b-form-group
                            label="Время работы (Будни)"
                            label-for="weekdays"
                        >
                          <b-form-input
                              v-model="description.working_hours_weekdays"
                              id="weekdays"
                              size="lg"
                              placeholder="Время работы (Будни)"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   WORKING TIME WEEKENDS   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Время работы (Отдых)`" rules="required" v-slot="{errors}">
                        <b-form-group
                            label="Время работы (Отдых)"
                            label-for="weekends"
                        >
                          <b-form-input
                              v-model="description.working_hours_weekends"
                              id="weekends"
                              size="lg"
                              placeholder="Время работы (Отдых)"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   PHONE NUMBER - 1   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Телефон номера`" rules="required" v-slot="{errors}">
                        <label for="select">Номер телефона</label>
                        <v-select
                            id="select"
                            taggable
                            multiple
                            push-tags
                            label="title"
                            v-model="description.phoneNumbers"
                        >

                        </v-select>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   MAP LINK   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Ссылка карты`" rules="regex:https" v-slot="{errors}">
                        <b-form-group
                            label="Ссылка карты"
                            label-for="map_link"
                        >
                          <b-form-input
                              v-model="map_url"
                              id="map_link"
                              size="lg"
                              placeholder="Ссылка карты"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   LINK [RU]   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Ссылка [RU]`" rules="regex:https" v-slot="{errors}">
                        <b-form-group
                            label="Ссылка [RU]"
                            label-for="link[ru]"
                        >
                          <b-form-input
                              v-model="url.ru"
                              id="link[ru]"
                              size="lg"
                              placeholder="Ссылка [RU]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   LINK [UZ]   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Ссылка [UZ]`" rules="regex:https" v-slot="{errors}">
                        <b-form-group
                            label="Ссылка [UZ]"
                            label-for="url_uz"
                        >
                          <b-form-input
                              v-model="url.uz"
                              id="url_ruz"
                              size="lg"
                              placeholder="Ссылка [UZ]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                  </div>
                </ValidationObserver>
              </template>

            </modal-button>

            <!--   DELETE   -->
            <modal-button
                v-if="!(data.item.id === 1 || data.item.id === 2)"
                class="crud__btn"
                ripple="rgba(113, 102, 240, 0.15)"
                size="sm"
                :name="`modal-delete-${data.item.id}`"
                modalButton="Удалить"
                modal-title="Удалить"
                variant="flat-danger"
                :id="data.item.id"
                ref="delete-modal"
                @btn-emit="deleteLocation"
            >
              <template
                  v-slot:button
              >
                <feather-icon
                    icon="Trash2Icon"
                    size="21"
                />
              </template>

              <template v-slot:modal-body>
                <div class="my-2">
                  Вы действительно хотите удалить эту локацию?
                </div>
              </template>

            </modal-button>

          </div>
        </template>
      </b-table>
    </b-col>

    <!--  PAGINATION  -->
    <b-col
        cols="12"
    >
      <b-pagination
          v-if="showPagination"
          v-model="pagination.current"
          :total-rows="pagination.total"
          :per-page="pagination.per_page"
          align="center"
          size="sm"
          class="my-0"
      />
    </b-col>

  </b-row>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BSpinner,
  BCard,
  BFormTextarea,
} from 'bootstrap-vue'
import ModalButton from "@/views/ui/modals/ModalButton";
import api from '@/services/api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss';

export default {
  name: "Locations",
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    BCard,
    BFormTextarea,
    ModalButton,
    ToastificationContent,
    vSelect
  },
  directives: {
    Ripple
  },
  data() {
    return {
      description: {
        address: {
          ru: '',
          uz: ''
        },
        city: {
          ru: '',
          uz: ''
        },
        working_hours_weekdays: '',
        working_hours_weekends: '',
        phoneNumbers: [],
      },
      map_url: '',
      url: {
        ru: '',
        uz: ''
      },
      title: {
        uz: '',
        ru: ''
      },
      showDeleteBtn: [],
      regions: [],

      isBusy: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'region_id',
          label: 'Регион',
        },
        {
          key: 'description.city.ru',
          label: 'Город',
        },
        {
          key: 'description.address.ru',
          label: 'Адрес',
        },
        {
          key: 'title.ru',
          label: 'Название',
        },
        {
          key: 'description.working_hours_weekdays',
          label: 'пон-пят',
        },
        {
          key: 'description.working_hours_weekends',
          label: 'суб-вос',
        },
        {
          key: 'description.phone',
          label: 'Телефон номера',
        },
        {
          key: 'map_url',
          label: 'ссылка карты',
        },
        {
          key: 'url',
          label: 'ссылка',
        },
        {
          key: 'crud_row',
          label: ' ',
        },
      ],
      items: [],
      pagination: {
        current: 1,
        total: 5,
        per_page: 5
      }
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        // const routeQueryPage = parseInt(query.page)
        this.pagination.current = query.page
      },
      deep: true,
      immediate: true
    },
    'pagination.current': {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        })
      },
    }
  },

  async mounted() {
    await this.getRegionsForSelect()
    await this.getLocations()
  },

  computed: {
    rows() {
      return this.items.length
    },

    query() {
      return Object.assign({}, this.$route.query)
    },

    hasItems() {
      return this.items.length > 0
    },

    showPagination() {
      return this.hasItems && !this.isBusy
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key,
          }))
    },
  },

  methods: {

    showToast(variant, text, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: icon,
          variant,
        },
      })
    },

    replaceRouter(query) {
      this.$router.replace({query})
          .catch(() => {
          })
    },

    clearInputs() {
      this.description.city.ru = ''
      this.description.city.uz = ''
      this.description.address.ru = ''
      this.description.address.uz = ''
      this.description.phoneNumbers = ''
      this.description.working_hours_weekdays = ''
      this.description.working_hours_weekends = ''
      this.title.ru = ''
      this.title.uz = ''
      this.map_url = ''
      this.url.ru = ''
      this.url.uz = ''
      this.region_id = null
    },

    async getLocations() {
      this.isBusy = true
      await api.locations.fetchLocations()
          .then(res => {
            this.items = res.data
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
          })
    },

    async createLocation() {
      const isValid = await this.$refs['validation-observer'].validate()
      if (isValid) {

        const formData = new FormData()
        formData.append('description[city][ru]', this.description.city.ru)
        formData.append('description[city][uz]', this.description.city.uz)
        formData.append('description[address][ru]', this.description.address.ru)
        formData.append('description[address][uz]', this.description.address.uz)
        formData.append('title[ru]', this.title.ru)
        formData.append('title[uz]', this.title.uz)
        formData.append('description[phone]', this.description.phoneNumbers)
        formData.append('description[working_hours_weekdays]', this.description.working_hours_weekdays)
        formData.append('description[working_hours_weekends]', this.description.working_hours_weekends)
        formData.append('url[ru]', this.url.ru)
        formData.append('url[uz]', this.url.uz)
        formData.append('map_url', this.map_url)

        api.locations.createNewLocation(formData)
            .then(res => {
              this.$refs['create-modal-button'].closeModal()
              this.showToast('success', 'Успешно создано!', 'CheckIcon')
            })
            .catch((error) => {
              console.error(error)
              this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
            })
            .finally(() => {
              this.getLocations()
              this.clearInputs()
            })
      }
    },

    async getOneLocation(id) {
      await api.locations.fetchOneLocation(id)
          .then(res => {
            this.description.city = res.data.description.city
            this.description.address = res.data.description.address
            this.description.working_hours_weekdays = res.data.description.working_hours_weekdays
            this.description.working_hours_weekends = res.data.description.working_hours_weekends
            this.description.phoneNumbers = res.data.description.phone.split(',')
            this.title = res.data.title
            this.map_url = res.data.map_url === 'null' ? '' : res.data.map_url
            this.url.ru = res.data.url.ru === 'null' ? '' : res.data.url.ru
            this.url.uz = res.data.url.uz === 'null' ? '' : res.data.url.uz
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
          })
    },

    async updateLocation(id) {
      const isValid = await this.$refs['validation-observer'].validate()
      if (isValid) {

        const formData = new FormData()
        formData.append('description[city][ru]', this.description.city.ru)
        formData.append('description[city][uz]', this.description.city.uz)
        formData.append('description[address][ru]', this.description.address.ru)
        formData.append('description[address][uz]', this.description.address.uz)
        formData.append('title[ru]', this.title.ru)
        formData.append('title[uz]', this.title.uz)
        formData.append('description[phone]', this.description.phoneNumbers)
        formData.append('description[working_hours_weekdays]', this.description.working_hours_weekdays)
        formData.append('description[working_hours_weekends]', this.description.working_hours_weekends)
        formData.append('url[ru]', this.url.ru)
        formData.append('url[uz]', this.url.uz)
        formData.append('map_url', this.map_url)

        api.locations.updateLocation(id, formData)
            .then(res => {
              this.showToast('success', 'Успешно обновлено!', 'CheckIcon')
            })
            .catch((error) => {
              console.error(error)
              this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
            })
            .finally(() => {
              this.$refs['update-modal-button'].closeModal()
              this.getLocations()
              this.clearInputs()
            })
      }
    },

    deleteLocation(id) {
      api.locations.deleteLocation(id)
          .then(res => {
            this.$refs["delete-modal"].closeModal()
            this.showToast('success', 'Успешно удалено!', 'CheckIcon')
          })
          .catch((error) => {
            console.error(error)
            this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
          })
          .finally(() => {
            this.getLocations()
          })
    },

    async getRegionsForSelect() {
      await api.regions.fetchRegions()
          .then(res => {
            this.regions = res.data.map(item => {
              return {
                title: item.name.ru,
                id: item.id
              }
            })
          })
          .catch(error => {
            console.error(error)
          })
    },

    getSelectRegionName(regions, item) {
      let currentOption = regions.find(region => {
        return region.id === parseInt(item.region_id)
      })
      if (currentOption) {
        return currentOption.title
      }
      return ''
    },

    splitPhoneNumbers(item) {
      console.log(item)
      if (item?.description?.phone) {
        return item.description.phone.replaceAll(',', ` `)
      }
      return ''
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss" scoped>

::v-deep .vs__selected-options {
  overflow: auto;
}

[dir] .dropdown-item {
  padding: 0;
}

::v-deep .vs__dropdown-toggle {
  height: 44px;
}

.validation__red {
  color: red;
  font-size: 12px;
  display: block;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 6px;
}

.crud__btn .btn {
  padding: 0.6rem;
}

</style>
